import { SOURCE, coerceId } from '@ember-data/store/-private';
import { warn } from '@ember/debug';
import { macroCondition, getGlobalConfig, dependencySatisfies, importSync } from '@embroider/macros';
import { createDeferred } from '@ember-data/request';
import { getOrSetGlobal } from '@warp-drive/core-types/-private';

/**
  @module @ember-data/legacy-compat
*/

/**
  SnapshotRecordArray is not directly instantiable.
  Instances are provided to consuming application's
  adapters for certain `findAll` requests.

  @class SnapshotRecordArray
  @public
*/
class SnapshotRecordArray {
  /**
    SnapshotRecordArray is not directly instantiable.
    Instances are provided to consuming application's
    adapters and serializers for certain requests.
     @method constructor
    @private
    @constructor
    @param {Store} store
    @param {string} type
    @param options
   */
  constructor(store, type, options = {}) {
    this.__store = store;
    /**
      An array of snapshots
      @private
      @property _snapshots
      @type {Array}
    */
    this._snapshots = null;

    /**
    The modelName of the underlying records for the snapshots in the array, as a Model
    @property modelName
    @public
    @type {Model}
    */
    this.modelName = type;

    /**
      A hash of adapter options passed into the store method for this request.
       Example
       ```app/adapters/post.js
      import MyCustomAdapter from './custom-adapter';
       export default class PostAdapter extends MyCustomAdapter {
        findAll(store, type, sinceToken, snapshotRecordArray) {
          if (snapshotRecordArray.adapterOptions.subscribe) {
            // ...
          }
          // ...
        }
      }
      ```
       @property adapterOptions
      @public
      @type {Object}
    */
    this.adapterOptions = options.adapterOptions;

    /**
      The relationships to include for this request.
       Example
       ```app/adapters/application.js
      import Adapter from '@ember-data/adapter';
       export default class ApplicationAdapter extends Adapter {
        findAll(store, type, snapshotRecordArray) {
          let url = `/${type.modelName}?include=${encodeURIComponent(snapshotRecordArray.include)}`;
           return fetch(url).then((response) => response.json())
        }
      }
      ```
       @property include
      @public
      @type {String|Array}
    */
    this.include = options.include;
  }

  /**
    An array of records
     @property _recordArray
    @private
    @type {Array}
  */
  get _recordArray() {
    return this.__store.peekAll(this.modelName);
  }

  /**
      Number of records in the array
       Example
       ```app/adapters/post.js
      import JSONAPIAdapter from '@ember-data/adapter/json-api';
       export default class PostAdapter extends JSONAPIAdapter {
        shouldReloadAll(store, snapshotRecordArray) {
          return !snapshotRecordArray.length;
        }
      });
      ```
       @property length
      @public
      @type {Number}
    */
  get length() {
    return this._recordArray.length;
  }

  /**
    Get snapshots of the underlying record array
     Example
     ```app/adapters/post.js
    import JSONAPIAdapter from '@ember-data/adapter/json-api';
     export default class PostAdapter extends JSONAPIAdapter {
      shouldReloadAll(store, snapshotArray) {
        let snapshots = snapshotArray.snapshots();
         return snapshots.any(function(ticketSnapshot) {
          let timeDiff = moment().diff(ticketSnapshot.attr('lastAccessedAt'), 'minutes');
          if (timeDiff > 20) {
            return true;
          } else {
            return false;
          }
        });
      }
    }
    ```
     @method snapshots
    @public
    @return {Array} Array of snapshots
  */
  snapshots() {
    if (this._snapshots !== null) {
      return this._snapshots;
    }
    upgradeStore(this.__store);
    const {
      _fetchManager
    } = this.__store;
    this._snapshots = this._recordArray[SOURCE].map(identifier => _fetchManager.createSnapshot(identifier));
    return this._snapshots;
  }
}
function assertIdentifierHasId(identifier) {
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`Attempted to schedule a fetch for a record without an id.`);
    }
  })(identifier && identifier.id !== null) : {};
}
function iterateData(data, fn) {
  if (Array.isArray(data)) {
    return data.map(fn);
  } else {
    return fn(data, 0);
  }
}
function payloadIsNotBlank(adapterPayload) {
  if (Array.isArray(adapterPayload)) {
    return true;
  } else {
    return Object.keys(adapterPayload || {}).length !== 0;
  }
}

/**
  This is a helper method that validates a JSON API top-level document

  The format of a document is described here:
  http://jsonapi.org/format/#document-top-level

  @internal
*/
function validateDocumentStructure(doc) {
  if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
    const errors = [];
    if (!doc || typeof doc !== 'object') {
      errors.push('Top level of a JSON API document must be an object');
    } else {
      if (!('data' in doc) && !('errors' in doc) && !('meta' in doc)) {
        errors.push('One or more of the following keys must be present: "data", "errors", "meta".');
      } else {
        if ('data' in doc && 'errors' in doc) {
          errors.push('Top level keys "errors" and "data" cannot both be present in a JSON API document');
        }
      }
      if ('data' in doc) {
        if (!(doc.data === null || Array.isArray(doc.data) || typeof doc.data === 'object')) {
          errors.push('data must be null, an object, or an array');
        }
      }
      if ('meta' in doc) {
        if (typeof doc.meta !== 'object') {
          errors.push('meta must be an object');
        }
      }
      if ('errors' in doc) {
        if (!Array.isArray(doc.errors)) {
          errors.push('errors must be an array');
        }
      }
      if ('links' in doc) {
        if (typeof doc.links !== 'object') {
          errors.push('links must be an object');
        }
      }
      if ('jsonapi' in doc) {
        if (typeof doc.jsonapi !== 'object') {
          errors.push('jsonapi must be an object');
        }
      }
      if ('included' in doc) {
        if (typeof doc.included !== 'object') {
          errors.push('included must be an array');
        }
      }
    }
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Response must be normalized to a valid JSON API document:\n\t* ${errors.join('\n\t* ')}`);
      }
    })(errors.length === 0) : {};
  }
}
function normalizeResponseHelper(serializer, store, modelClass, payload, id, requestType) {
  const normalizedResponse = serializer ? serializer.normalizeResponse(store, modelClass, payload, id, requestType) : payload;
  validateDocumentStructure(normalizedResponse);
  return normalizedResponse;
}

/**
  @module @ember-data/store
*/
/**
  Snapshot is not directly instantiable.
  Instances are provided to a consuming application's
  adapters and serializers for certain requests.

  Snapshots are only available when using `@ember-data/legacy-compat`
  for legacy compatibility with adapters and serializers.

  @class Snapshot
  @public
*/
class Snapshot {
  /**
   * @method constructor
   * @constructor
   * @private
   * @param options
   * @param identifier
   * @param _store
   */
  constructor(options, identifier, store) {
    this._store = store;
    this.__attributes = null;
    this._belongsToRelationships = Object.create(null);
    this._belongsToIds = Object.create(null);
    this._hasManyRelationships = Object.create(null);
    this._hasManyIds = Object.create(null);
    const hasRecord = !!store._instanceCache.peek(identifier);
    this.modelName = identifier.type;

    /**
      The unique RecordIdentifier associated with this Snapshot.
       @property identifier
      @public
      @type {StableRecordIdentifier}
    */
    this.identifier = identifier;

    /*
      If the we do not yet have a record, then we are
      likely a snapshot being provided to a find request, so we
      populate __attributes lazily. Else, to preserve the "moment
      in time" in which a snapshot is created, we greedily grab
      the values.
     */
    if (hasRecord) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      this._attributes;
    }

    /**
     The id of the snapshot's underlying record
      Example
      ```javascript
     // store.push('post', { id: 1, author: 'Tomster', title: 'Ember.js rocks' });
     postSnapshot.id; // => '1'
     ```
      @property id
     @type {String}
     @public
     */
    this.id = identifier.id;

    /**
     A hash of adapter options
     @property adapterOptions
     @type {Object}
     @public
     */
    this.adapterOptions = options.adapterOptions;

    /**
     If `include` was passed to the options hash for the request, the value
     would be available here.
      @property include
     @type {String|Array}
     @public
     */
    this.include = options.include;

    /**
     The name of the type of the underlying record for this snapshot, as a string.
      @property modelName
     @type {String}
     @public
     */
    this.modelName = identifier.type;
    if (hasRecord) {
      const cache = this._store.cache;
      this._changedAttributes = cache.changedAttrs(identifier);
    }
  }

  /**
   The underlying record for this snapshot. Can be used to access methods and
   properties defined on the record.
    Example
    ```javascript
   let json = snapshot.record.toJSON();
   ```
    @property record
   @type {Model}
   @public
   */
  get record() {
    const record = this._store.peekRecord(this.identifier);
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Record ${this.identifier.type} ${this.identifier.id} (${this.identifier.lid}) is not yet loaded and thus cannot be accessed from the Snapshot during serialization`);
      }
    })(record !== null) : {};
    return record;
  }
  get _attributes() {
    if (this.__attributes !== null) {
      return this.__attributes;
    }
    const attributes = this.__attributes = Object.create(null);
    const {
      identifier
    } = this;
    const attrs = this._store.schema.fields(identifier);
    const cache = this._store.cache;
    attrs.forEach((field, keyName) => {
      if (field.kind === 'attribute') {
        attributes[keyName] = cache.getAttr(identifier, keyName);
      }
    });
    return attributes;
  }
  get isNew() {
    const cache = this._store.cache;
    return cache?.isNew(this.identifier) || false;
  }

  /**
   Returns the value of an attribute.
    Example
    ```javascript
   // store.push('post', { id: 1, author: 'Tomster', title: 'Ember.js rocks' });
   postSnapshot.attr('author'); // => 'Tomster'
   postSnapshot.attr('title'); // => 'Ember.js rocks'
   ```
    Note: Values are loaded eagerly and cached when the snapshot is created.
    @method attr
   @param {String} keyName
   @return {Object} The attribute value or undefined
   @public
   */
  attr(keyName) {
    if (keyName in this._attributes) {
      return this._attributes[keyName];
    }
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      {
        throw new Error(`Model '${this.identifier.lid}' has no attribute named '${keyName}' defined.`);
      }
    })() : {};
  }

  /**
   Returns all attributes and their corresponding values.
    Example
    ```javascript
   // store.push('post', { id: 1, author: 'Tomster', title: 'Ember.js rocks' });
   postSnapshot.attributes(); // => { author: 'Tomster', title: 'Ember.js rocks' }
   ```
    @method attributes
   @return {Object} All attributes of the current snapshot
   @public
   */
  attributes() {
    return {
      ...this._attributes
    };
  }

  /**
   Returns all changed attributes and their old and new values.
    Example
    ```javascript
   // store.push('post', { id: 1, author: 'Tomster', title: 'Ember.js rocks' });
   postModel.set('title', 'Ember.js rocks!');
   postSnapshot.changedAttributes(); // => { title: ['Ember.js rocks', 'Ember.js rocks!'] }
   ```
    @method changedAttributes
   @return {Object} All changed attributes of the current snapshot
   @public
   */
  changedAttributes() {
    const changedAttributes = Object.create(null);
    if (!this._changedAttributes) {
      return changedAttributes;
    }
    const changedAttributeKeys = Object.keys(this._changedAttributes);
    for (let i = 0, length = changedAttributeKeys.length; i < length; i++) {
      const key = changedAttributeKeys[i];
      changedAttributes[key] = this._changedAttributes[key].slice();
    }
    return changedAttributes;
  }

  /**
   Returns the current value of a belongsTo relationship.
    `belongsTo` takes an optional hash of options as a second parameter,
   currently supported options are:
    - `id`: set to `true` if you only want the ID of the related record to be
   returned.
    Example
    ```javascript
   // store.push('post', { id: 1, title: 'Hello World' });
   // store.createRecord('comment', { body: 'Lorem ipsum', post: post });
   commentSnapshot.belongsTo('post'); // => Snapshot
   commentSnapshot.belongsTo('post', { id: true }); // => '1'
    // store.push('comment', { id: 1, body: 'Lorem ipsum' });
   commentSnapshot.belongsTo('post'); // => undefined
   ```
    Calling `belongsTo` will return a new Snapshot as long as there's any known
   data for the relationship available, such as an ID. If the relationship is
   known but unset, `belongsTo` will return `null`. If the contents of the
   relationship is unknown `belongsTo` will return `undefined`.
    Note: Relationships are loaded lazily and cached upon first access.
    @method belongsTo
   @param {String} keyName
   @param {Object} [options]
   @public
   @return {(Snapshot|String|null|undefined)} A snapshot or ID of a known
   relationship or null if the relationship is known but unset. undefined
   will be returned if the contents of the relationship is unknown.
   */
  belongsTo(keyName, options) {
    const returnModeIsId = !!(options && options.id);
    let result;
    const store = this._store;
    if (returnModeIsId === true && keyName in this._belongsToIds) {
      return this._belongsToIds[keyName];
    }
    if (returnModeIsId === false && keyName in this._belongsToRelationships) {
      return this._belongsToRelationships[keyName];
    }
    const relationshipMeta = store.schema.fields({
      type: this.modelName
    }).get(keyName);
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Model '${this.identifier.lid}' has no belongsTo relationship named '${keyName}' defined.`);
      }
    })(relationshipMeta && relationshipMeta.kind === 'belongsTo') : {};
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`snapshot.belongsTo only supported when using the package @ember-data/graph`);
      }
    })(dependencySatisfies('@ember-data/graph', '*')) : {};
    const graphFor = importSync('@ember-data/graph/-private').graphFor;
    const {
      identifier
    } = this;
    if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
      const relationship = graphFor(this._store).get(identifier, keyName);
      macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
        if (!test) {
          throw new Error(`You looked up the ${keyName} belongsTo relationship for { type: ${identifier.type}, id: ${identifier.id || ''}, lid: ${identifier.lid} but no such relationship was found.`);
        }
      })(relationship) : {};
      macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
        if (!test) {
          throw new Error(`You looked up the ${keyName} belongsTo relationship for { type: ${identifier.type}, id: ${identifier.id || ''}, lid: ${identifier.lid} but that relationship is a hasMany.`);
        }
      })(relationship.definition.kind === 'belongsTo') : {};
    }
    const value = graphFor(this._store).getData(identifier, keyName);
    const data = value && value.data;
    const inverseIdentifier = data ? store.identifierCache.getOrCreateRecordIdentifier(data) : null;
    if (value && value.data !== undefined) {
      const cache = store.cache;
      if (inverseIdentifier && !cache.isDeleted(inverseIdentifier)) {
        if (returnModeIsId) {
          result = inverseIdentifier.id;
        } else {
          result = store._fetchManager.createSnapshot(inverseIdentifier);
        }
      } else {
        result = null;
      }
    }
    if (returnModeIsId) {
      this._belongsToIds[keyName] = result;
    } else {
      this._belongsToRelationships[keyName] = result;
    }
    return result;
  }

  /**
   Returns the current value of a hasMany relationship.
    `hasMany` takes an optional hash of options as a second parameter,
   currently supported options are:
    - `ids`: set to `true` if you only want the IDs of the related records to be
   returned.
    Example
    ```javascript
   // store.push('post', { id: 1, title: 'Hello World', comments: [2, 3] });
   postSnapshot.hasMany('comments'); // => [Snapshot, Snapshot]
   postSnapshot.hasMany('comments', { ids: true }); // => ['2', '3']
    // store.push('post', { id: 1, title: 'Hello World' });
   postSnapshot.hasMany('comments'); // => undefined
   ```
    Note: Relationships are loaded lazily and cached upon first access.
    @method hasMany
   @param {String} keyName
   @param {Object} [options]
   @public
   @return {(Array|undefined)} An array of snapshots or IDs of a known
   relationship or an empty array if the relationship is known but unset.
   undefined will be returned if the contents of the relationship is unknown.
   */
  hasMany(keyName, options) {
    const returnModeIsIds = !!(options && options.ids);
    let results;
    const cachedIds = this._hasManyIds[keyName];
    const cachedSnapshots = this._hasManyRelationships[keyName];
    if (returnModeIsIds === true && keyName in this._hasManyIds) {
      return cachedIds;
    }
    if (returnModeIsIds === false && keyName in this._hasManyRelationships) {
      return cachedSnapshots;
    }
    const store = this._store;
    const relationshipMeta = store.schema.fields({
      type: this.modelName
    }).get(keyName);
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Model '${this.identifier.lid}' has no hasMany relationship named '${keyName}' defined.`);
      }
    })(relationshipMeta && relationshipMeta.kind === 'hasMany') : {};

    // TODO @runspired it seems this code branch would not work with CUSTOM_MODEL_CLASSes
    // this check is not a regression in behavior because relationships don't currently
    // function without access to intimate API contracts between RecordData and Model.
    // This is a requirement we should fix as soon as the relationship layer does not require
    // this intimate API usage.
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`snapshot.hasMany only supported when using the package @ember-data/graph`);
      }
    })(dependencySatisfies('@ember-data/graph', '*')) : {};
    const graphFor = importSync('@ember-data/graph/-private').graphFor;
    const {
      identifier
    } = this;
    if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
      const relationship = graphFor(this._store).get(identifier, keyName);
      macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
        if (!test) {
          throw new Error(`You looked up the ${keyName} hasMany relationship for { type: ${identifier.type}, id: ${identifier.id || ''}, lid: ${identifier.lid} but no such relationship was found.`);
        }
      })(relationship) : {};
      macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
        if (!test) {
          throw new Error(`You looked up the ${keyName} hasMany relationship for { type: ${identifier.type}, id: ${identifier.id || ''}, lid: ${identifier.lid} but that relationship is a belongsTo.`);
        }
      })(relationship.definition.kind === 'hasMany') : {};
    }
    const value = graphFor(this._store).getData(identifier, keyName);
    if (value.data) {
      results = [];
      value.data.forEach(member => {
        const inverseIdentifier = store.identifierCache.getOrCreateRecordIdentifier(member);
        const cache = store.cache;
        if (!cache.isDeleted(inverseIdentifier)) {
          if (returnModeIsIds) {
            results.push(inverseIdentifier.id);
          } else {
            results.push(store._fetchManager.createSnapshot(inverseIdentifier));
          }
        }
      });
    }

    // we assign even if `undefined` so that we don't reprocess the relationship
    // on next access. This works with the `keyName in` checks above.
    if (returnModeIsIds) {
      this._hasManyIds[keyName] = results;
    } else {
      this._hasManyRelationships[keyName] = results;
    }
    return results;
  }

  /**
    Iterates through all the attributes of the model, calling the passed
    function on each attribute.
     Example
     ```javascript
    snapshot.eachAttribute(function(name, meta) {
      // ...
    });
    ```
     @method eachAttribute
    @param {Function} callback the callback to execute
    @param {Object} [binding] the value to which the callback's `this` should be bound
    @public
  */
  eachAttribute(callback, binding) {
    const fields = this._store.schema.fields(this.identifier);
    fields.forEach((field, key) => {
      if (field.kind === 'attribute') {
        callback.call(binding, key, field);
      }
    });
  }

  /**
    Iterates through all the relationships of the model, calling the passed
    function on each relationship.
     Example
     ```javascript
    snapshot.eachRelationship(function(name, relationship) {
      // ...
    });
    ```
     @method eachRelationship
    @param {Function} callback the callback to execute
    @param {Object} [binding] the value to which the callback's `this` should be bound
    @public
  */
  eachRelationship(callback, binding) {
    const fields = this._store.schema.fields(this.identifier);
    fields.forEach((field, key) => {
      if (field.kind === 'belongsTo' || field.kind === 'hasMany') {
        callback.call(binding, key, field);
      }
    });
  }

  /**
    Serializes the snapshot using the serializer for the model.
     Example
     ```app/adapters/application.js
    import Adapter from '@ember-data/adapter';
     export default Adapter.extend({
      createRecord(store, type, snapshot) {
        let data = snapshot.serialize({ includeId: true });
        let url = `/${type.modelName}`;
         return fetch(url, {
          method: 'POST',
          body: data,
        }).then((response) => response.json())
      }
    });
    ```
     @method serialize
    @param {Object} options
    @return {Object} an object whose values are primitive JSON values only
    @public
   */
  serialize(options) {
    upgradeStore(this._store);
    const serializer = this._store.serializerFor(this.modelName);
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Cannot serialize record, no serializer found`);
      }
    })(serializer) : {};
    return serializer.serialize(this, options);
  }
}
const SaveOp = getOrSetGlobal('SaveOp', Symbol('SaveOp'));
class FetchManager {
  // fetches pending in the runloop, waiting to be coalesced

  constructor(store) {
    this._store = store;
    // used to keep track of all the find requests that need to be coalesced
    this._pendingFetch = new Map();
    this.requestCache = store.getRequestStateService();
    this.isDestroyed = false;
  }
  createSnapshot(identifier, options = {}) {
    return new Snapshot(options, identifier, this._store);
  }

  /**
    This method is called by `record.save`, and gets passed a
    resolver for the promise that `record.save` returns.
     It schedules saving to happen at the end of the run loop.
     @internal
  */
  scheduleSave(identifier, options) {
    const resolver = createDeferred();
    const query = {
      op: 'saveRecord',
      recordIdentifier: identifier,
      options
    };
    const queryRequest = {
      data: [query]
    };
    const snapshot = this.createSnapshot(identifier, options);
    const pendingSaveItem = {
      snapshot: snapshot,
      resolver: resolver,
      identifier,
      options,
      queryRequest
    };
    const monitored = this.requestCache._enqueue(resolver.promise, pendingSaveItem.queryRequest);
    _flushPendingSave(this._store, pendingSaveItem);
    return monitored;
  }
  scheduleFetch(identifier, options, request) {
    const query = {
      op: 'findRecord',
      recordIdentifier: identifier,
      options
    };
    const queryRequest = {
      data: [query]
    };
    const pendingFetch = this.getPendingFetch(identifier, options);
    if (pendingFetch) {
      return pendingFetch;
    }
    const modelName = identifier.type;
    const resolver = createDeferred();
    const pendingFetchItem = {
      identifier,
      resolver,
      options,
      queryRequest
    };
    const resolverPromise = resolver.promise;
    const store = this._store;
    const isInitialLoad = !store._instanceCache.recordIsLoaded(identifier); // we don't use isLoading directly because we are the request

    const monitored = this.requestCache._enqueue(resolverPromise, pendingFetchItem.queryRequest);
    let promise = monitored.then(payload => {
      // ensure that regardless of id returned we assign to the correct record
      if (payload.data && !Array.isArray(payload.data)) {
        payload.data.lid = identifier.lid;
      }

      // additional data received in the payload
      // may result in the merging of identifiers (and thus records)
      const potentiallyNewIm = store._push(payload, options.reload);
      if (potentiallyNewIm && !Array.isArray(potentiallyNewIm)) {
        return potentiallyNewIm;
      }
      return identifier;
    }, error => {
      macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
        if (!test) {
          throw new Error(`Async Leak Detected: Expected the store to not be destroyed`);
        }
      })(!store.isDestroyed) : {};
      const cache = store.cache;
      if (!cache || cache.isEmpty(identifier) || isInitialLoad) {
        let isReleasable = true;
        if (macroCondition(dependencySatisfies('@ember-data/graph', '*'))) {
          if (!cache) {
            const graphFor = importSync('@ember-data/graph/-private').graphFor;
            const graph = graphFor(store);
            isReleasable = graph.isReleasable(identifier);
            if (!isReleasable) {
              graph.unload(identifier, true);
            }
          }
        }
        if (cache || isReleasable) {
          store._enableAsyncFlush = true;
          store._instanceCache.unloadRecord(identifier);
          store._enableAsyncFlush = null;
        }
      }
      throw error;
    });
    if (this._pendingFetch.size === 0) {
      void new Promise(resolve => setTimeout(resolve, 0)).then(() => {
        this.flushAllPendingFetches();
      });
    }
    const fetchesByType = this._pendingFetch;
    let fetchesById = fetchesByType.get(modelName);
    if (!fetchesById) {
      fetchesById = new Map();
      fetchesByType.set(modelName, fetchesById);
    }
    let requestsForIdentifier = fetchesById.get(identifier);
    if (!requestsForIdentifier) {
      requestsForIdentifier = [];
      fetchesById.set(identifier, requestsForIdentifier);
    }
    requestsForIdentifier.push(pendingFetchItem);
    if (macroCondition(getGlobalConfig().WarpDrive.env.TESTING)) {
      if (!request.disableTestWaiter) {
        const {
          waitForPromise
        } = importSync('@ember/test-waiters');
        promise = waitForPromise(promise);
      }
    }
    pendingFetchItem.promise = promise;
    return promise;
  }
  getPendingFetch(identifier, options) {
    const pendingFetches = this._pendingFetch.get(identifier.type)?.get(identifier);

    // We already have a pending fetch for this
    if (pendingFetches) {
      const matchingPendingFetch = pendingFetches.find(fetch => isSameRequest(options, fetch.options));
      if (matchingPendingFetch) {
        return matchingPendingFetch.promise;
      }
    }
  }
  flushAllPendingFetches() {
    if (this.isDestroyed) {
      return;
    }
    const store = this._store;
    this._pendingFetch.forEach((fetchItem, type) => _flushPendingFetchForType(store, fetchItem, type));
    this._pendingFetch.clear();
  }
  fetchDataIfNeededForIdentifier(identifier, options = {}, request) {
    // pre-loading will change the isEmpty value
    const isEmpty = _isEmpty(this._store._instanceCache, identifier);
    const isLoading = _isLoading(this._store._instanceCache, identifier);
    let promise;
    if (isEmpty) {
      assertIdentifierHasId(identifier);
      if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
        promise = this.scheduleFetch(identifier, Object.assign({}, options, {
          reload: true
        }), request);
      } else {
        options.reload = true;
        promise = this.scheduleFetch(identifier, options, request);
      }
    } else if (isLoading) {
      promise = this.getPendingFetch(identifier, options);
      macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
        if (!test) {
          throw new Error(`Expected to find a pending request for a record in the loading state, but found none`);
        }
      })(promise) : {};
    } else {
      promise = Promise.resolve(identifier);
    }
    return promise;
  }
  destroy() {
    this.isDestroyed = true;
  }
}
function _isEmpty(instanceCache, identifier) {
  const cache = instanceCache.cache;
  if (!cache) {
    return true;
  }
  const isNew = cache.isNew(identifier);
  const isDeleted = cache.isDeleted(identifier);
  const isEmpty = cache.isEmpty(identifier);
  return (!isNew || isDeleted) && isEmpty;
}
function _isLoading(cache, identifier) {
  const req = cache.store.getRequestStateService();
  // const fulfilled = req.getLastRequestForRecord(identifier);
  const isLoaded = cache.recordIsLoaded(identifier);
  return !isLoaded &&
  // fulfilled === null &&
  req.getPendingRequestsForRecord(identifier).some(r => r.type === 'query');
}
function includesSatisfies(current, existing) {
  // if we have no includes we are good
  if (!current?.length) {
    return true;
  }

  // if we are here we have includes,
  // and if existing has no includes then we will need a new request
  if (!existing?.length) {
    return false;
  }
  const arrCurrent = (Array.isArray(current) ? current : current.split(',')).sort();
  const arrExisting = (Array.isArray(existing) ? existing : existing.split(',')).sort();

  // includes are identical
  if (arrCurrent.join(',') === arrExisting.join(',')) {
    return true;
  }

  // if all of current includes are in existing includes then we are good
  // so if we find one that is not in existing then we need a new request
  for (let i = 0; i < arrCurrent.length; i++) {
    if (!arrExisting.includes(arrCurrent[i])) {
      return false;
    }
  }
  return true;
}
function optionsSatisfies(current, existing) {
  return !current || current === existing || Object.keys(current).length === 0;
}

// this function helps resolve whether we have a pending request that we should use instead
function isSameRequest(options = {}, existingOptions = {}) {
  return optionsSatisfies(options.adapterOptions, existingOptions.adapterOptions) && includesSatisfies(options.include, existingOptions.include);
}
function _findMany(store, adapter, modelName, snapshots) {
  const modelClass = store.modelFor(modelName); // `adapter.findMany` gets the modelClass still
  const promise = Promise.resolve().then(() => {
    const ids = snapshots.map(s => s.id);
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Cannot fetch a record without an id`);
      }
    })(ids.every(v => v !== null)) : {};
    // eslint-disable-next-line @typescript-eslint/unbound-method
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Expected this adapter to implement findMany for coalescing`);
      }
    })(adapter.findMany) : {};
    const ret = adapter.findMany(store, modelClass, ids, snapshots);
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error('adapter.findMany returned undefined, this was very likely a mistake');
      }
    })(ret !== undefined) : {};
    return ret;
  });
  return promise.then(adapterPayload => {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`You made a 'findMany' request for '${modelName}' records with ids '[${snapshots.map(s => s.id).join(',')}]', but the adapter's response did not have any data`);
      }
    })(!!payloadIsNotBlank(adapterPayload)) : {};
    const serializer = store.serializerFor(modelName);
    const payload = normalizeResponseHelper(serializer, store, modelClass, adapterPayload, null, 'findMany');
    return payload;
  });
}
function rejectFetchedItems(fetchMap, snapshots, error) {
  for (let i = 0, l = snapshots.length; i < l; i++) {
    const snapshot = snapshots[i];
    const pair = fetchMap.get(snapshot);
    if (pair) {
      pair.resolver.reject(error || new Error(`Expected: '<${snapshot.modelName}:${snapshot.id}>' to be present in the adapter provided payload, but it was not found.`));
    }
  }
}
function handleFoundRecords(store, fetchMap, snapshots, coalescedPayload) {
  /*
    It is possible that the same ID is included multiple times
    via multiple snapshots. This happens when more than one
    options hash was supplied, each of which must be uniquely
    accounted for.
     However, since we can't map from response to a specific
    options object, we resolve all snapshots by id with
    the first response we see.
  */
  const snapshotsById = new Map();
  for (let i = 0; i < snapshots.length; i++) {
    const id = snapshots[i].id;
    let snapshotGroup = snapshotsById.get(id);
    if (!snapshotGroup) {
      snapshotGroup = [];
      snapshotsById.set(id, snapshotGroup);
    }
    snapshotGroup.push(snapshots[i]);
  }
  const included = Array.isArray(coalescedPayload.included) ? coalescedPayload.included : [];

  // resolve found records
  const resources = coalescedPayload.data;
  for (let i = 0, l = resources.length; i < l; i++) {
    const resource = resources[i];
    const snapshotGroup = snapshotsById.get(resource.id);
    snapshotsById.delete(resource.id);
    if (!snapshotGroup) {
      // TODO consider whether this should be a deprecation/assertion
      included.push(resource);
    } else {
      snapshotGroup.forEach(snapshot => {
        const pair = fetchMap.get(snapshot);
        const resolver = pair.resolver;
        resolver.resolve({
          data: resource
        });
      });
    }
  }
  if (included.length > 0) {
    store._push({
      data: null,
      included
    }, true);
  }
  if (snapshotsById.size === 0) {
    return;
  }

  // reject missing records
  const rejected = [];
  snapshotsById.forEach(snapshotArray => {
    rejected.push(...snapshotArray);
  });
  warn('Ember Data expected to find records with the following ids in the adapter response from findMany but they were missing: [ "' + [...snapshotsById.values()].map(r => r[0].id).join('", "') + '" ]', {
    id: 'ds.store.missing-records-from-adapter'
  });
  rejectFetchedItems(fetchMap, rejected);
}
function _fetchRecord(store, adapter, fetchItem) {
  const identifier = fetchItem.identifier;
  const modelName = identifier.type;
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`You tried to find a record but you have no adapter (for ${modelName})`);
    }
  })(adapter) : {};
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`You tried to find a record but your adapter (for ${modelName}) does not implement 'findRecord'`);
    }
  })(typeof adapter.findRecord === 'function') : {};
  const snapshot = store._fetchManager.createSnapshot(identifier, fetchItem.options);
  const klass = store.modelFor(identifier.type);
  const id = identifier.id;
  let promise = Promise.resolve().then(() => {
    return adapter.findRecord(store, klass, identifier.id, snapshot);
  });
  promise = promise.then(adapterPayload => {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Async Leak Detected: Expected the store to not be destroyed`);
      }
    })(!(store.isDestroyed || store.isDestroying)) : {};
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`You made a 'findRecord' request for a '${modelName}' with id '${id}', but the adapter's response did not have any data`);
      }
    })(!!payloadIsNotBlank(adapterPayload)) : {};
    const serializer = store.serializerFor(modelName);
    const payload = normalizeResponseHelper(serializer, store, klass, adapterPayload, id, 'findRecord');
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Ember Data expected the primary data returned from a 'findRecord' response to be an object but instead it found an array.`);
      }
    })(!Array.isArray(payload.data)) : {};
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`The 'findRecord' request for ${modelName}:${id} resolved indicating success but contained no primary data. To indicate a 404 not found you should either reject the promise returned by the adapter's findRecord method or throw a NotFoundError.`);
      }
    })('data' in payload && payload.data !== null && typeof payload.data === 'object') : {};
    warn(`You requested a record of type '${modelName}' with id '${id}' but the adapter returned a payload with primary data having an id of '${payload.data.id}'. Use 'store.findRecord()' when the requested id is the same as the one returned by the adapter. In other cases use 'store.queryRecord()' instead.`, coerceId(payload.data.id) === coerceId(id), {
      id: 'ds.store.findRecord.id-mismatch'
    });
    return payload;
  });
  fetchItem.resolver.resolve(promise);
}
function _processCoalescedGroup(store, fetchMap, group, adapter, modelName) {
  if (group.length > 1) {
    _findMany(store, adapter, modelName, group).then(payloads => {
      handleFoundRecords(store, fetchMap, group, payloads);
    }).catch(error => {
      rejectFetchedItems(fetchMap, group, error);
    });
  } else if (group.length === 1) {
    _fetchRecord(store, adapter, fetchMap.get(group[0]));
  } else {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      {
        throw new Error("You cannot return an empty array from adapter's method groupRecordsForFindMany");
      }
    })() : {};
  }
}
function _flushPendingFetchForType(store, pendingFetchMap, modelName) {
  const adapter = store.adapterFor(modelName);
  const shouldCoalesce = !!adapter.findMany && adapter.coalesceFindRequests;
  if (shouldCoalesce) {
    const pendingFetchItems = [];
    pendingFetchMap.forEach((requestsForIdentifier, identifier) => {
      if (requestsForIdentifier.length > 1) {
        return;
      }

      // remove this entry from the map so it's not processed again
      pendingFetchMap.delete(identifier);
      pendingFetchItems.push(requestsForIdentifier[0]);
    });
    const totalItems = pendingFetchItems.length;
    if (totalItems > 1) {
      const snapshots = new Array(totalItems);
      const fetchMap = new Map();
      for (let i = 0; i < totalItems; i++) {
        const fetchItem = pendingFetchItems[i];
        snapshots[i] = store._fetchManager.createSnapshot(fetchItem.identifier, fetchItem.options);
        fetchMap.set(snapshots[i], fetchItem);
      }
      let groups;
      if (adapter.groupRecordsForFindMany) {
        groups = adapter.groupRecordsForFindMany(store, snapshots);
      } else {
        groups = [snapshots];
      }
      for (let i = 0, l = groups.length; i < l; i++) {
        _processCoalescedGroup(store, fetchMap, groups[i], adapter, modelName);
      }
    } else if (totalItems === 1) {
      _fetchRecord(store, adapter, pendingFetchItems[0]);
    }
  }
  pendingFetchMap.forEach(pendingFetchItems => {
    pendingFetchItems.forEach(pendingFetchItem => {
      _fetchRecord(store, adapter, pendingFetchItem);
    });
  });
}
function _flushPendingSave(store, pending) {
  const {
    snapshot,
    resolver,
    identifier,
    options
  } = pending;
  const adapter = store.adapterFor(identifier.type);
  const operation = options[SaveOp];
  const modelName = snapshot.modelName;
  const modelClass = store.modelFor(modelName);
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`You tried to update a record but you have no adapter (for ${modelName})`);
    }
  })(adapter) : {};
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`You tried to update a record but your adapter (for ${modelName}) does not implement '${operation}'`);
    }
  })(typeof adapter[operation] === 'function') : {};
  let promise = Promise.resolve().then(() => adapter[operation](store, modelClass, snapshot));
  const serializer = store.serializerFor(modelName);
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`Your adapter's '${operation}' method must return a value, but it returned 'undefined'`);
    }
  })(promise !== undefined) : {};
  promise = promise.then(adapterPayload => {
    if (adapterPayload) {
      return normalizeResponseHelper(serializer, store, modelClass, adapterPayload, snapshot.id, operation);
    }
  });
  resolver.resolve(promise);
}

/**
 * Utilities - often temporary - for maintaining backwards compatibility with
 * older parts of EmberData.
 *
  @module @ember-data/legacy-compat
  @main @ember-data/legacy-compat
*/
function upgradeStore(store) {}
export { FetchManager as F, SaveOp as S, assertIdentifierHasId as a, SnapshotRecordArray as b, Snapshot as c, iterateData as i, normalizeResponseHelper as n, payloadIsNotBlank as p, upgradeStore as u };