import { dasherize, pluralize } from '@ember-data/request-utils/string';
import '@ember/debug';
import { macroCondition, getGlobalConfig } from '@embroider/macros';
import "./build-url-mixin-DWfVjH3O.js";
import { b as serializeIntoHash } from "./serialize-into-hash-DIFDGeuT.js";
import RESTAdapter from "./rest.js";

/**
  @module @ember-data/adapter/json-api
 */

class JSONAPIAdapter extends RESTAdapter {
  _defaultContentType = 'application/vnd.api+json';

  /**
    @method ajaxOptions
    @private
    @param {String} url
    @param {String} type The request type GET, POST, PUT, DELETE etc.
    @param {Object} options
    @return {Object}
  */
  ajaxOptions(url, type, options = {}) {
    const hash = super.ajaxOptions(url, type, options);
    const headers = hash.headers = hash.headers || {};
    headers['Accept'] = headers['Accept'] || 'application/vnd.api+json';
    return hash;
  }

  /**
    By default the JSONAPIAdapter will send each find request coming from a `store.find`
    or from accessing a relationship separately to the server. If your server supports passing
    ids as a query string, you can set coalesceFindRequests to true to coalesce all find requests
    within a single runloop.
     For example, if you have an initial payload of:
     ```javascript
    {
      data: {
        id: 1,
        type: 'post',
        relationship: {
          comments: {
            data: [
              { id: 1, type: 'comment' },
              { id: 2, type: 'comment' }
            ]
          }
        }
      }
    }
    ```
     By default calling `post.comments` will trigger the following requests(assuming the
    comments haven't been loaded before):
     ```
    GET /comments/1
    GET /comments/2
    ```
     If you set coalesceFindRequests to `true` it will instead trigger the following request:
     ```
    GET /comments?filter[id]=1,2
    ```
     Setting coalesceFindRequests to `true` also works for `store.find` requests and `belongsTo`
    relationships accessed within the same runloop. If you set `coalesceFindRequests: true`
     ```javascript
    store.findRecord('comment', 1);
    store.findRecord('comment', 2);
    ```
     will also send a request to: `GET /comments?filter[id]=1,2`
     Note: Requests coalescing rely on URL building strategy. So if you override `buildURL` in your app
    `groupRecordsForFindMany` more likely should be overridden as well in order for coalescing to work.
     @property coalesceFindRequests
    @public
    @type {boolean}
  */
  get coalesceFindRequests() {
    const coalesceFindRequests = this._coalesceFindRequests;
    if (typeof coalesceFindRequests === 'boolean') {
      return coalesceFindRequests;
    }
    return this._coalesceFindRequests = false;
  }
  set coalesceFindRequests(value) {
    this._coalesceFindRequests = value;
  }
  findMany(store, type, ids, snapshots) {
    const url = this.buildURL(type.modelName, ids, snapshots, 'findMany');
    return this.ajax(url, 'GET', {
      data: {
        filter: {
          id: ids.join(',')
        }
      }
    });
  }
  pathForType(modelName) {
    const dasherized = dasherize(modelName);
    return pluralize(dasherized);
  }
  updateRecord(store, schema, snapshot) {
    const data = serializeIntoHash(store, schema, snapshot);
    const type = snapshot.modelName;
    const id = snapshot.id;
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Attempted to update the ${type} record, but the record has no id`);
      }
    })(typeof id === 'string' && id.length > 0) : {};
    const url = this.buildURL(type, id, snapshot, 'updateRecord');
    return this.ajax(url, 'PATCH', {
      data: data
    });
  }

  /**
    Used by `findAll` and `findRecord` to build the query's `data` hash
    supplied to the ajax method.
     @method buildQuery
    @since 2.5.0
    @public
    @param  {Snapshot} snapshot
    @return {Object}
  */
  buildQuery(snapshot) {
    const query = {};
    if (snapshot) {
      const {
        include
      } = snapshot;
      const normalizedInclude = Array.isArray(include) ? include.join(',') : include;
      if (normalizedInclude) {
        query.include = normalizedInclude;
      }
    }
    return query;
  }
}
export { JSONAPIAdapter as default };