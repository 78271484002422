import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import CompetenceRow from './competence-row';
export default class EvaluationResultsDetailsTab extends Component {
    get groupedCompetencesByArea() {
        return this.args.competenceResults.reduce((areas1, competenceResult1)=>{
            const existingArea1 = areas1.find((area1)=>area1.areaTitle === competenceResult1.areaTitle);
            if (existingArea1) {
                existingArea1.competences.push(competenceResult1);
            } else {
                areas1.push({
                    areaTitle: competenceResult1.areaTitle,
                    areaColor: competenceResult1.areaColor,
                    competences: [
                        competenceResult1
                    ]
                });
            }
            return areas1;
        }, []);
    }
    get total() {
        return this.args.totalStage - 1;
    }
    static{
        template(`
    <h2 class="evaluation-results-tab__title">{{t "pages.skill-review.tabs.results-details.title"}}</h2>
    <p class="evaluation-results-tab__description">{{t "pages.skill-review.tabs.results-details.description"}}</p>

    <ol class="evaluation-results-tab__areas">
      {{#each this.groupedCompetencesByArea as |area|}}
        <li class="evaluation-results-tab__area">
          <h3 class="evaluation-results-tab__area-title evaluation-results-tab__area-title--{{area.areaColor}}">
            {{area.areaTitle}}
          </h3>
          <table class="evaluation-results-tab__competences-table">
            <tbody>
              {{#each area.competences as |competence|}}
                <CompetenceRow @competence={{competence}} @total={{this.total}} />
              {{/each}}
            </tbody>
          </table>
        </li>
      {{/each}}
    </ol>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
