import { template } from "@ember/template-compiler";
import PixBanner from '@1024pix/pix-ui/components/pix-banner';
import { t } from 'ember-intl';
export default template(`
  <PixBanner @type="communication">
    {{t "pages.modulix.beta-banner"}}
  </PixBanner>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
