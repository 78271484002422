import { tagForProperty } from '@ember/-internals/metal';
import { consumeTag, dirtyTag } from '@glimmer/validator';
import { getOrSetGlobal, peekTransient, setTransient } from '@warp-drive/core-types/-private';
import { macroCondition, getGlobalConfig } from '@embroider/macros';
function createTransaction() {
  const transaction = {
    cbs: new Set(),
    props: new Set(),
    sub: new Set(),
    parent: null
  };
  const TRANSACTION = peekTransient('TRANSACTION');
  if (TRANSACTION) {
    transaction.parent = TRANSACTION;
  }
  setTransient('TRANSACTION', transaction);
}
function maybeConsume(tag) {
  if (tag) {
    consumeTag(tag);
  }
}
function maybeDirty(tag) {
  if (tag) {
    // @ts-expect-error - we are using Ember's Tag not Glimmer's
    dirtyTag(tag);
  }
}

/**
 * If there is a current transaction, ensures that the relevant tag (and any
 * array computed chains symbols, if applicable) will be consumed during the
 * transaction.
 *
 * If there is no current transaction, will consume the tag(s) immediately.
 *
 * @internal
 * @param obj
 */
function subscribe(obj) {
  const TRANSACTION = peekTransient('TRANSACTION');
  if (TRANSACTION) {
    TRANSACTION.sub.add(obj);
  } else if ('tag' in obj) {
    if (macroCondition(getGlobalConfig().WarpDrive.deprecations.DEPRECATE_COMPUTED_CHAINS)) {
      maybeConsume(obj['[]']);
      maybeConsume(obj['@length']);
    }
    consumeTag(obj.tag);
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    obj.ref;
  }
}
function updateRef(obj) {
  if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
    try {
      if ('tag' in obj) {
        if (macroCondition(getGlobalConfig().WarpDrive.deprecations.DEPRECATE_COMPUTED_CHAINS)) {
          maybeDirty(obj['[]']);
          maybeDirty(obj['@length']);
        }
        // @ts-expect-error - we are using Ember's Tag not Glimmer's
        dirtyTag(obj.tag);
      } else {
        obj.ref = null;
      }
    } catch (e) {
      if (e instanceof Error) {
        if (e.message.includes('You attempted to update `undefined`')) {
          // @ts-expect-error
          const key = `<${obj._debug_base}>.${obj.key}`;
          e.message = e.message.replace('You attempted to update `undefined`', `You attempted to update ${key}`);
          e.stack = e.stack?.replace('You attempted to update `undefined`', `You attempted to update ${key}`);
          const lines = e.stack?.split(`\n`);
          const finalLines = [];
          let lastFile = null;
          lines?.forEach(line => {
            if (line.trim().startsWith('at ')) {
              // get the last string in the line which contains the code source location
              const location = line.split(' ').at(-1);
              // remove the line and char offset info

              if (location.includes(':')) {
                const parts = location.split(':');
                parts.pop();
                parts.pop();
                const file = parts.join(':');
                if (file !== lastFile) {
                  lastFile = file;
                  finalLines.push('');
                }
              }
              finalLines.push(line);
            }
          });
          const splitstr = '`undefined` was first used:';
          const parts = e.message.split(splitstr);
          parts.splice(1, 0, `Original Stack\n=============\n${finalLines.join(`\n`)}\n\n\`${key}\` was first used:`);
          e.message = parts.join('');
        }
      }
      throw e;
    }
  } else {
    if ('tag' in obj) {
      if (macroCondition(getGlobalConfig().WarpDrive.deprecations.DEPRECATE_COMPUTED_CHAINS)) {
        maybeDirty(obj['[]']);
        maybeDirty(obj['@length']);
      }
      // @ts-expect-error - we are using Ember's Tag not Glimmer's
      dirtyTag(obj.tag);
    } else {
      obj.ref = null;
    }
  }
}
function flushTransaction() {
  const transaction = peekTransient('TRANSACTION');
  setTransient('TRANSACTION', transaction.parent);
  transaction.cbs.forEach(cb => {
    cb();
  });
  transaction.props.forEach(obj => {
    // mark this mutation as part of a transaction
    obj.t = true;
    updateRef(obj);
  });
  transaction.sub.forEach(obj => {
    if ('tag' in obj) {
      if (macroCondition(getGlobalConfig().WarpDrive.deprecations.DEPRECATE_COMPUTED_CHAINS)) {
        maybeConsume(obj['[]']);
        maybeConsume(obj['@length']);
      }
      consumeTag(obj.tag);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      obj.ref;
    }
  });
}
async function untrack() {
  const transaction = peekTransient('TRANSACTION');
  setTransient('TRANSACTION', transaction.parent);

  // defer writes
  await Promise.resolve();
  transaction.cbs.forEach(cb => {
    cb();
  });
  transaction.props.forEach(obj => {
    // mark this mutation as part of a transaction
    obj.t = true;
    updateRef(obj);
  });
}
function addToTransaction(obj) {
  const transaction = peekTransient('TRANSACTION');
  if (transaction) {
    transaction.props.add(obj);
  } else {
    updateRef(obj);
  }
}
function addTransactionCB(method) {
  const transaction = peekTransient('TRANSACTION');
  if (transaction) {
    transaction.cbs.add(method);
  } else {
    method();
  }
}

/**
 * Run `method` without subscribing to any tracked properties
 * controlled by EmberData.
 *
 * This should rarely be used except by libraries that really
 * know what they are doing. It is most useful for wrapping
 * certain kinds of fetch/query logic from within a `Resource`
 * `hook` or other similar pattern.
 *
 * @function untracked
 * @public
 * @static
 * @for @ember-data/tracking
 * @param method
 * @return result of invoking method
 */
function untracked(method) {
  createTransaction();
  const ret = method();
  void untrack();
  return ret;
}

/**
 * Run the method, subscribing to any tracked properties
 * managed by EmberData that were accessed or written during
 * the method's execution as per-normal but while allowing
 * interleaving of reads and writes.
 *
 * This is useful when for instance you want to perform
 * a mutation based on existing state that must be read first.
 *
 * @function transact
 * @public
 * @static
 * @for @ember-data/tracking
 * @param method
 * @return result of invoking method
 */
function transact(method) {
  createTransaction();
  const ret = method();
  flushTransaction();
  return ret;
}

/**
 * A helpful utility for creating a new function that
 * always runs in a transaction. E.G. this "memoizes"
 * calling `transact(fn)`, currying args as necessary.
 *
 * @method memoTransact
 * @public
 * @static
 * @for @ember-data/tracking
 * @param method
 * @return a function that will invoke method in a transaction with any provided args and return its result
 */
function memoTransact(method) {
  return function (...args) {
    createTransaction();
    const ret = method(...args);
    flushTransaction();
    return ret;
  };
}
const Signals = getOrSetGlobal('Signals', Symbol('Signals'));

/**
 *  use to add a signal property to the prototype of something.
 *
 *  First arg is the thing to define on
 *  Second arg is the property name
 *  Third agg is the initial value of the property if any.
 *
 *  for instance
 *
 *  ```ts
 *  class Model {}
 *  defineSignal(Model.prototype, 'isLoading', false);
 *  ```
 *
 *  This is sort of like using a stage-3 decorator but works today
 *  while we are still on legacy decorators.
 *
 *  e.g. it is equivalent to
 *
 *  ```ts
 *  class Model {
 *    @signal accessor isLoading = false;
 *  }
 *  ```
 *
 *  @internal
 */
function defineSignal(obj, key, v) {
  Object.defineProperty(obj, key, {
    enumerable: true,
    configurable: false,
    get() {
      const signals = this[Signals] = this[Signals] || new Map();
      const existing = signals.has(key);
      const _signal = entangleSignal(signals, this, key);
      if (!existing && v !== undefined) {
        _signal.lastValue = v;
      }
      return _signal.lastValue;
    },
    set(value) {
      const signals = this[Signals] = this[Signals] || new Map();
      let _signal = signals.get(key);
      if (!_signal) {
        _signal = createSignal(this, key);
        signals.set(key, _signal);
      }
      if (_signal.lastValue !== value) {
        _signal.lastValue = value;
        addToTransaction(_signal);
      }
    }
  });
}
function createArrayTags(obj, signal) {
  if (macroCondition(getGlobalConfig().WarpDrive.deprecations.DEPRECATE_COMPUTED_CHAINS)) {
    signal['[]'] = tagForProperty(obj, '[]');
    signal['@length'] = tagForProperty(obj, 'length');
  }
}

/**
 * Create a signal for the key/object pairing.
 *
 * @internal
 * @param obj Object we're creating the signal on
 * @param key Key to create the signal for
 * @return the signal
 */
function createSignal(obj, key) {
  const _signal = {
    key,
    tag: tagForProperty(obj, key),
    t: false,
    shouldReset: false,
    '[]': null,
    '@length': null,
    lastValue: undefined
  };
  if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
    function tryGet(prop) {
      try {
        return obj[prop];
      } catch {
        return;
      }
    }
    const modelName = tryGet('$type') ?? tryGet('modelName') ?? tryGet('constructor')?.modelName ?? '';
    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    const className = obj.constructor?.name ?? obj.toString?.() ?? 'unknown';
    _signal._debug_base = `${className}${modelName && !className.startsWith('SchemaRecord') ? `:${modelName}` : ''}`;
  }
  return _signal;
}

/**
 * Create a signal for the key/object pairing and subscribes to the signal.
 *
 * Use when you need to ensure a signal exists and is subscribed to.
 *
 * @internal
 * @param signals Map of signals
 * @param obj Object we're creating the signal on
 * @param key Key to create the signal for
 * @return the signal
 */
function entangleSignal(signals, obj, key) {
  let _signal = signals.get(key);
  if (!_signal) {
    _signal = createSignal(obj, key);
    signals.set(key, _signal);
  }
  subscribe(_signal);
  return _signal;
}
function getSignal(obj, key, initialState) {
  let signals = obj[Signals];
  if (!signals) {
    signals = new Map();
    obj[Signals] = signals;
  }
  let _signal = signals.get(key);
  if (!_signal) {
    _signal = createSignal(obj, key);
    _signal.shouldReset = initialState;
    signals.set(key, _signal);
  }
  return _signal;
}
function peekSignal(obj, key) {
  const signals = obj[Signals];
  if (signals) {
    return signals.get(key);
  }
}
export { Signals, addToTransaction, addTransactionCB, createArrayTags, createSignal, defineSignal, entangleSignal, getSignal, memoTransact, peekSignal, subscribe, transact, untracked };