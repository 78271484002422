import { template } from "@ember/template-compiler";
import PixSelect from '@1024pix/pix-ui/components/pix-select';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { t } from 'ember-intl';
export default class OidcProviderSelector extends Component {
    @tracked
    selectedProvider;
    get providerOptions() {
        const { providers: providers1 = [] } = this.args;
        const options1 = providers1.map((provider1)=>({
                label: provider1.organizationName,
                value: provider1.id
            }));
        return options1.sort((option11, option21)=>option11.label.localeCompare(option21.label));
    }
    @action
    onProviderChange(value1) {
        this.selectedProvider = value1;
        if (this.args.onProviderChange) {
            this.args.onProviderChange(this.selectedProvider);
        }
    }
    static{
        template(`
    <PixSelect
      @hideDefaultOption="true"
      @id="oidc-provider-selector"
      @isSearchable="true"
      @onChange={{this.onProviderChange}}
      @options={{this.providerOptions}}
      @placeholder={{t "components.authentication.oidc-provider-selector.placeholder"}}
      @searchLabel={{t "components.authentication.oidc-provider-selector.searchLabel"}}
      @searchPlaceholder={{t "components.authentication.oidc-provider-selector.searchLabel"}}
      @value={{this.selectedProvider}}
      class="oidc-provider-selector"
      ...attributes
    >
      <:label>{{t "components.authentication.oidc-provider-selector.label"}}</:label>
    </PixSelect>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
