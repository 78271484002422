import { template } from "@ember/template-compiler";
import PixButtonLink from '@1024pix/pix-ui/components/pix-button-link';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import MarkdownToHtml from '../../../../markdown-to-html';
export default class EvaluationResultsCustomOrganizationBlock extends Component {
    get customButtonUrl() {
        if (this.args.campaign.customResultPageButtonUrl && this.args.campaign.customResultPageButtonText) {
            const params1 = {};
            if (Number.isFinite(this.args.campaignParticipationResult.masteryRate)) {
                params1.masteryPercentage = Number(this.args.campaignParticipationResult.masteryRate * 100).toFixed(0);
            }
            params1.externalId = this.args.campaignParticipationResult.participantExternalId ?? undefined;
            params1.stage = this.args.campaignParticipationResult.reachedStage?.get('threshold') ?? undefined;
            return buildUrl(this.args.campaign.customResultPageButtonUrl, params1);
        } else {
            return null;
        }
    }
    static{
        template(`
    <div class="evaluation-results-hero__organization-block">
      <h3 class="evaluation-results-hero-organization-block__title">
        {{t "pages.skill-review.organization-message"}}
      </h3>
      {{#if @campaign.customResultPageText}}
        <MarkdownToHtml
          class="evaluation-results-hero-organization-block__message"
          @isInline={{true}}
          @markdown={{@campaign.customResultPageText}}
        />
      {{/if}}
      {{#if this.customButtonUrl}}
        <PixButtonLink
          class="evaluation-results-hero-organization-block__link"
          @href={{this.customButtonUrl}}
          @variant="secondary"
        >
          {{@campaign.customResultPageButtonText}}
        </PixButtonLink>
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
function buildUrl(baseUrl1, params1) {
    const Url1 = new URL(baseUrl1);
    const urlParams1 = new URLSearchParams(Url1.search);
    for(const key1 in params1){
        if (params1[key1] !== undefined) {
            urlParams1.set(key1, params1[key1]);
        }
    }
    Url1.search = urlParams1.toString();
    return Url1.toString();
}
