import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import PasswordRule from './password-rule';
export default class PasswordChecklist extends Component {
    get displayedRules() {
        const { rules: rules1 = [], value: value1 } = this.args;
        return rules1.map((rule1)=>({
                ...rule1,
                isValid: Boolean(value1) && rule1.validate(value1)
            }));
    }
    get rulesCount() {
        const { rules: rules1 = [] } = this.args;
        return rules1.length;
    }
    get rulesCompleted() {
        const { rules: rules1 = [], value: value1 } = this.args;
        const invalidRulesCount1 = this.displayedRules.filter((rule1)=>!rule1.isValid).length;
        return !value1 ? 0 : rules1.length - invalidRulesCount1;
    }
    static{
        template(`
    <div class="password-checklist" ...attributes>
      <label class="password-checklist__instructions" for="checklist">
        {{t "components.authentication.new-password-input.instructions-label"}}
      </label>
      <ul id="checklist">
        {{#each this.displayedRules as |rule|}}
          <PasswordRule @description={{t rule.i18nKey}} @isValid={{rule.isValid}} />
        {{/each}}
      </ul>
      <p class="sr-only" aria-atomic="true" aria-relevant="all" aria-live="polite">
        {{t
          "components.authentication.new-password-input.completed-message"
          rulesCompleted=this.rulesCompleted
          rulesCount=this.rulesCount
        }}
      </p>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
